import React, { useState, useRef } from "react";
import Layout from "../layout/Layout";
import useSearch from "../hooks/searchspring/useSearch";
import ProductFilters from "../components/ProductFilters";
import Breadcrumbs from "../components/common/breadcrumbs/Breadcrumbs";
import ProductSortFilter from "../components/searchSpring/ProductSortFilter";
import ProductListing from "../components/products/ProductListing";
import Pagination from "../components/common/Pagination";
import { SearchContext } from "../context/SearchContext";

const ProductPageTemplate = ({ location }) => {
  const pageWrapperRef = useRef(null);
  const [view, setProductView] = useState("grid");

  const {
    filters,
    products,
    sortingOptions,
    isLoading,
    pagination,
    breadcrumbs,
    facets,
    filterSummary,
    handlePageChange,
    applyFilter,
    handlePriceChange,
    onPriceReset,
    onClearAllFilter,
    handleSortingOptionChange,
  } = useSearch(null, null, pageWrapperRef);

  const { breadcrumbTitle } = breadcrumbs;
  const { currentPage, totalPages } = pagination;

  const contextValues = {
    filters,
    isLoading,
    products,
    facets,
    view,
    filterSummary,
    sortingOptions,
    setProductView,
    applyFilter,
    handlePriceChange,
    onPriceReset,
    onClearAllFilter,
    handleSortingOptionChange,
  };

  return (
    <SearchContext.Provider value={contextValues}>
      <div className="page-wrapper" ref={pageWrapperRef}>
        <div className="container">
          <Breadcrumbs location={location} title={breadcrumbTitle} />
        </div>
        <div className="container">
          <div className="row flex flex-wrap page-layout">
            <div className="col page-sidebar">
              <ProductFilters />
            </div>
            <div className="col page-content">
              <div className="page-action-bar flex justify-space vertical-middle">
                <ProductSortFilter />
              </div>

              <ProductListing />
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={totalPages}
                onPageChange={(page) => handlePageChange(page)}
              />
            </div>
          </div>
        </div>
      </div>
    </SearchContext.Provider>
  );
};

const ProductPage = ({ data, pageContext }) => {
  return (
    <ProductPageTemplate pageContext={pageContext} />
  );
};

ProductPage.Layout = Layout
export default ProductPage;
